import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsAccountManager from './components/LanguageOptionsAccountManager';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- You will develop and coach on engagement strategies for customers, partners, and internal stakeholders.",
          "- You will establish and enhance relationships with key stakeholders (internal and external) to ensure successful solution delivery.",
          "- You will expand relationships beyond current contracts by aligning our strategies with customer priorities.",
          "- You will strive to achieve “Trusted Advisor” status with key customer executives.",

    ]
}

const content2 = {
  title: "Required Skills And Qualifications:",
  content: [
            "- Bachelor's Degree in Business, Sociology, Psychology, Computer Science or related field AND 4+ years customer success, solution delivery, practice management, customer-facing consulting, or portfolio management experience",
            "- OR Master's Degree in Business, Sociology, Psychology, Computer Science, or related field AND 3+ years customer success, solution delivery, practice management, customer-facing consulting, or portfolio management experience",
            "- OR equivalent experience.",
           
    ]
}

const companyURL = "https://www.gemini.com/";


function AppAccountManager() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$180K/yr - $250K/yr" company="Gemini" companyURL={companyURL} />
          <LanguageOptionsAccountManager company="Gemini" title="Account Manager" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppAccountManager;
