import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import LanguageOptionsConsultants from './components/LanguageOptionsConsultants'
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';




const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Current or former Business Professional, Executive, B2B Sales Rep, Finance Executive, CFO, COO, or Controller.",
          "- Self-motivator who routinely sets and exceeds their own goals. If you're goal oriented and self-motivated you'lll thrive here.",
          "- Proven track record of relationship-building and influencing.",
          "- A business professional who wants to change their financial disposition."
    ]
}

const content2 = {
  title: "",
  content: [
            
    ]
}

const companyURL = "https://Gemini.com/";


function AppIsofu() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$150K/yr - $250K/yr" company="Gemini" companyURL={companyURL} />
          <LanguageOptionsConsultants company="Gemini" title="Senior Business Consultant" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppIsofu;
